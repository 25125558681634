import React, { useState } from "react";
import { useWatch, useController } from "react-hook-form";

import RadioItemsSelect from "../RadioItemsSelect/RadioItemsSelect";
import TaxonomySelect from "../TaxonomySelect";

import { ImCross } from "react-icons/im";

const SignupBonusField = ({
  item,
  array,
  index,
  removeField,
  control,
  currenciesData,
  countriesData,
}) => {
  const [showBonusCountries, setShowBonusCountries] = useState(false);
  const [showBonusCurrency, setShowBonusCurrency] = useState(false);

  const currency = useWatch({
    control,
    name: `bonusesOnSignUp[${index}].currency`,
  });

  const watchCountries = useWatch({
    control,
    name: `bonusesOnSignUp[${index}].countries`,
  });

  const countries = Object.keys(watchCountries || {}).map(
    (id) => watchCountries[id].name
  );

  const { field } = useController({
    name: `bonusesOnSignUp[${index}].amount`,
    control,
  });

  const currencyBtnText = showBonusCurrency ? "Close" : "Add currency";
  const countryBtnText = showBonusCountries ? "Close" : "Add countries";

  return (
    <div key={index}>
      <ImCross
        style={{ color: "#ff0000", marginRight: "10px", cursor: "pointer" }}
        onClick={removeField}
      />
      <input
        className="bonus-inputs"
        type="number"
        {...field}
        placeholder="Amount"
      ></input>
      {currency?.length > 0 ? (
        <span
          className="bonus-inputs"
          style={{ marginLeft: "5px", marginRight: "20px", padding: "9px" }}
        >
          {currency}
        </span>
      ) : null}
      {countries?.length > 0 ? (
        <span
          className="bonus-inputs"
          style={{ marginLeft: "5px", marginRight: "20px", padding: "9px" }}
        >
          Countries:
          {countries?.map((country, i) => {
            const marginfirst = i === 0 ? "10px" : "";
            const divider = i === countries?.length - 1 ? "" : ", ";
            if (i <= 4) {
              return (
                <span style={{ marginLeft: `${marginfirst}` }}>
                  {country}
                  {divider}
                </span>
              );
            }
          })}
          {countries.length > 5 ? (
            <span>
              and{" "}
              <span style={{ fontWeight: "bold" }}>{countries.length - 5}</span>{" "}
              more.
            </span>
          ) : null}
        </span>
      ) : null}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "50%", textAlign: "center" }}>
          <button
            style={{
              backgroundColor: "#3ece84",
              border: "none",
              padding: "5px",
              cursor: "pointer",
              fontWeight: "bold",
              borderRadius: "2px",
              color: "white",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            onClick={() => {
              setShowBonusCurrency(!showBonusCurrency);
            }}
          >
            {currencyBtnText}
          </button>
        </div>
        <div style={{ width: "50%", textAlign: "center" }}>
          <button
            style={{
              backgroundColor: "#3ece84",
              border: "none",
              padding: "5px",
              cursor: "pointer",
              fontWeight: "bold",
              borderRadius: "2px",
              color: "white",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            onClick={() => {
              setShowBonusCountries(!showBonusCountries);
            }}
          >
            {countryBtnText}
          </button>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <RadioItemsSelect
          overrideStyling
          width="half"
          hideState={!showBonusCurrency}
          taxonomyType="radio"
          name={`bonusesOnSignUp[${index}].currency`}
          control={control}
          items={currenciesData}
        ></RadioItemsSelect>
        <TaxonomySelect
          overrideStyling
          canAddManually={false}
          canSelectAll={false}
          width="half"
          taxonomyType="checkbox"
          hideState={!showBonusCountries}
          name={`bonusesOnSignUp[${index}].countries`}
          control={control}
          taxonomyItems={countriesData}
        ></TaxonomySelect>
      </div>
    </div>
  );
};

export default SignupBonusField;
